<template>
    <div>
        <Nav />

        <Tittle :type="'courses'">
            <h1 class="fadeInUp"><span></span>Explore on Faslearn</h1>
			<form role="search" id="searchform" method="get">
                <input class="form-control form-control-lg search" type="text" placeholder="Search ..." @keydown="search()" v-model="searchItem">
                <br>
				<button type="button" @click="search()" class="btn btn-primary" style="background-color: #EF6421; border: none">
                    <i class="icon_search"></i> Search now
				</button>
			</form>
        </Tittle>
		
		<!-- /filters -->
		<div class="container margin_60_35">
			<div class="row">
				<aside class="col-lg-3" id="sidebar">
					<div id="filters_col"> <a data-toggle="collapse" href="#collapseFilters" aria-expanded="false" aria-controls="collapseFilters" id="filters_col_bt">Filters </a>
						<div class="collapse show" id="collapseFilters">
							<div class="filter_type">
								<h6>Category</h6>
								<h6 :class="{active : type == 'course'}" @click="switchType('course')" style="cursor: pointer"><i class="icon-fixed-width icon-book"></i> Search Courses</h6>
								
								<h6 :class="{active : type == 'instructor'}" @click="switchType('instructor')" style="cursor: pointer"><i class="icon-user-1"></i> Search Teachers</h6>

                                <h6 :class="{active : type == 'institution'}" @click="switchType('institution')" style="cursor: pointer"><i class="icon-shop-1"></i> Search Institutions</h6>
							</div>
						</div>
						<!--/collapse -->
					</div>
					<!--/filters col-->
				</aside>
				<!-- /aside -->
				
				<div class="col-lg-9">
					
					<div class="row" v-if="type=='course'">
						<Loading v-if="Loading" />
                       
						<div class="col-md-6" v-for="cos in results" :key="cos">
							<router-link :to="'/coursedetail/'+cos._id">
							<div class="box_grid wow">
								<figure class="block-reveal">
									<div class="block-horizzontal"></div>
									<a href="#0" class="wish_bt"></a>
									<a href="">
                                        <CourseImage
                                            :name="cos.thumbnailFile"
                                            style="height: 200px; width: 100%"
                                        />
                                    </a>
									<div class="price">₦{{cos.price.$numberDecimal}}</div>
									<div class="preview"><span>Preview course</span></div>
								</figure>
								<div class="wrapper">
									<small>{{cos.category[0].name}}</small>
									<h3>{{cos.title}}</h3>
									<p>{{cos.secondTitle}}</p>
									<Ratings :id="cos._id" :type="'course'" />
								</div>
								<ul>
									<li><i class="icon_profile"></i> {{cos.user[0].lastName.substring(0,21)}}</li>
									<!-- <li><i class="icon_like"></i></li> -->
									<li><a >Enroll now</a></li>
								</ul>
							</div>
							</router-link>
						</div>
					</div>
                    
                    <div class="row" v-if="type=='instructor'">
						<Loading v-if="Loading" />
						<div class="col-md-6" v-for="t in results" :key="t">
							<div class="box_grid wow">
								<figure class="block-reveal" style="max-height: 300px">
									<div class="block-horizzontal"></div>
									<a href="#0" class="wish_bt"></a>
									<ProfileImage :id="t._id" :name="t.imageFile" />
									<div class="price"></div>
								</figure>
								<router-link :to="'/teacher/'+t._id">
								<div class="wrapper">
									<!-- <small>{{t.expertise}}</small> -->
									<h3>{{t.firstName}} {{t.lastName}}</h3>
									<!-- <Ratings :id="t._id" :type="'instructor'" /> -->
								</div>
								</router-link>
							</div>
						</div>
						
					</div>
                    <div class="row" v-if="type=='institution'">
                        <div class="container margin_60_35">
                            <Loading v-if="Loading" />
                            <div class="box_list wow" v-for="ins in results" :key="ins" style="min-height: 200px; padding: 10px">
                                <router-link :to="'/institude/'+ins._id">
                                <div class="row no-gutters" style="min-height: 200px; padding: 10px">
                                    <div class="col-lg-5">
                                        <figure class="block-reveal">
                                            <div class="block-horizzontal"></div>
                                            <a href=""><img :src="backEnd+'public/institutionImage__public/'+ins._id" alt="" style="height: 200px; width: 200px; border-radius: 50%"></a>
                                            <div class="preview"><span>Preview Institution</span></div>
                                        </figure>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="wrapper">
                                            <a href="#0" class="wish_bt"></a>
                                            <Ratings :id="ins._id" :type="'institution'" />
                                            <small>{{ins.institutionType}}</small>
                                            <h3>{{ins.institutionName}}</h3>
                                            <p>{{ins.address}} </p>
                                        </div>
                                        <ul>
                                            <li><a href="">Enroll now</a></li>
                                        </ul>
                                    </div>
                                </div>
                                </router-link>
                            </div>
						</div>
					</div>
					<!-- /row -->
<!-- the dummy div for unavailable course  -->
          <!-- <EmptySearch v-if="empty" type="course" /> -->

          <div class="row" v-if="empty">
            <div class="col-md-12">
              <div class="box_grid wow">
                <div class="wrapper" >
                  <img
                    v-if="type == 'course'"
                    src="./search-asset/images/course.jpg"
                    class="img-fluid"
                    alt="Course not available"
                  />
                  <img
                    class="img-fluid"
                    v-if="type == 'instructor'"
                    src="./search-asset/images/instructor.jpg"
                    alt="Instructor not available"
                  />
                  <img
                    class="img-fluid"
                    v-if="type == 'institution'"
                    src="./search-asset/images/institution.jpg"
                    alt="Institution not available"
                  />
                </div>
              </div>
            </div>
          </div>
 <!-- end of dummy div -->
          <p class="text-center"  @click.prevent="fetchMore" v-if="!Loading && !empty">
            <a href="" class="btn_1 rounded add_top_30">Load more</a>
          </p>

                    <p class="text-center" v-if="Loading"><a href="" class="btn_1 rounded add_top_30">Please wait..</a></p>
				</div>
				<!-- /col -->
			</div>
			<!-- /row -->
		</div>

        <Footer />
    </div>
</template>
<script>
//course institution instructor
import {mapActions, mapGetters} from 'vuex'
import ProfileImage from "@/components/ProfileImage";
import Nav from '@/components/site/Nav.vue'
import Ratings from '@/components/site/Ratings.vue'
import Loading from "@/components/Loading";
import Tittle from '@/components/site/Tittle.vue'
import Footer from '@/components/site/Footer.vue'
import CourseImage from "@/components/CourseImage";
export default {
	name: 'Allcourses',
	data(){
		return{
			category: '',
			courses: '',
			picked: 'e',
			Loading: false,
            categories: '',
            searchItem: '',
            type : 'course',
            empty: false,
            results: '',
			skip: 0
		}
	},
	computed: {
		...mapGetters(['circle']),
		...mapGetters(['backEnd']),
         
	},
    components: {
        Nav,
		Tittle,
		Loading,
        Ratings,
        ProfileImage,
        CourseImage,
        Footer
    },
	methods: {
        ...mapActions(['search_Items','fetch_recent_courses__public']),
        switchType(type){
            this.skip = 0
            this.Loading = true
            this.results = []
            let object = {
                search: this.searchItem,
                type: type,
                skip: this.skip
            }
            this.search_Items(object)
            .then(res => {
                this.results = res.data
                this.Loading = false
                this.tried = true  
                this.type = type
            })
        },

		search(){
            this.skip = 0
            this.Loading = true
            let object = {
                search: this.searchItem,
                type: this.type,
                skip: this.skip
            }
            this.search_Items(object)
            .then(res => {
                this.results = res.data
                this.Loading = false
                this.tried = true
                if (this.results == 0 ) {
                     this.empty = true;
                } else{
                   this.empty = false
                }
            })
        },
        fetchMore(){
            this.skip = this.skip + 10
            this.Loading = true
            let object = {
                search: this.searchItem,
                type: this.type,
                skip: this.skip
            }
            this.search_Items(object)
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    this.results.push(element)
                }
                this.Loading = false
                this.tried = true
            })
        }
	},
    mounted(){

        this.fetch_recent_courses__public(this.skip)  
        .then(res => {
            this.courses = res.data
            this.results = this.courses
		})
		
  	}
}
</script>
<style scoped>
    .active{
		padding: 15px;
		color: white;
		background-color: #EF6421;
		border-radius: 20%;
	}

    .search{
        padding: 5px; 
        border-radius: 10px;
        font-size: 15px;
    }

	
</style>